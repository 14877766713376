import { Injectable } from '@angular/core';
import { GroupDTO, UserFuncAvatarDTO } from 'common';

@Injectable({
  providedIn: 'root',
})
export class FeedbackData {
  groupSelected: GroupDTO = null;
  idFeedbackCategory: number = 0;
  message: string = null;
  users: UserFuncAvatarDTO[] = [];

  idFeedbackSolicitation: number = 0;
  subject: string = null;
  description: string = null;

  constructor() {}

  reset() {
    this.groupSelected = null;
    this.idFeedbackCategory = 0;
    this.message = null;
    this.users = [];
    this.idFeedbackSolicitation = 0;
    this.subject = null;
    this.description = null;
  }
}
