import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { Ic2Module } from '@ic2/ic2-lib';
import { TranslateModule } from '@ngx-translate/core';
import { HijiCommonModule, TRANSLATE_CHILD_CONFIG } from 'common';
import { FeedbackMessageComponent } from './feedback-message.component';

@NgModule({
  declarations: [FeedbackMessageComponent],
  imports: [
    CommonModule,
    HijiCommonModule,
    Ic2Module,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(TRANSLATE_CHILD_CONFIG),
    RouterModule.forChild([]),
  ],
  exports: [FeedbackMessageComponent],
})
export class FeedbackMessageModule {}
