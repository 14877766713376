<div class="container-fluid border rounded-3 bg-white position-relative g-0">
  <div class="row g-0 align-items-center mt-2">
    <div class="col-auto">
      <div class="text-center" style="width: 50px">
        <i class="fa-light fa-share fa-2x text-primary" [class.fa-rotate-180]="feedback.feedback.idUserSender !== idUserLogged"></i>
      </div>
    </div>

    <div class="col my-fs-3">
      <span
        *ngIf="feedback.feedback.recipientType === FeedbackRecipientType.USER && feedback.users.length == 1 && feedback.feedback.idUserSender !== idUserLogged"
      >
        <b>{{ 'feedback.message.Feedback personnel' | translate }}</b>
        &nbsp;
      </span>
      <!-- <span *ngIf="feedback.feedback.recipientType === FeedbackRecipientType.USER && feedback.feedback.idUserSender !== idUserLogged">
        <b>{{ 'feedback.message.reçu de' | translate }}</b>
        &nbsp;

        <span *ngFor="let user of feedback.users; let last = last">{{ user.firstName }} {{ user.lastName }}{{ last ? '' : ', ' }}</span>
      </span> -->
      <span
        *ngIf="feedback.feedback.recipientType === FeedbackRecipientType.USER && feedback.users.length == 1 && feedback.feedback.idUserSender === idUserLogged"
      >
        <b>{{ 'feedback.message.Feedback envoyé à' | translate }}</b>
        :
        <a class="text-decoration-underline pointer" [routerLink]="null" (click)="openModalUsers(feedback.users)">
          <span *ngFor="let user of feedback.users; let last = last">{{ user.firstName }} {{ user.lastName }}{{ last ? '' : ', ' }}</span>
        </a>
      </span>
      <span *ngIf="feedback.feedback.recipientType === FeedbackRecipientType.USER && feedback.users.length == 2">
        <b>{{ 'feedback.message.Feedback envoyé à' | translate }}</b>
        :
        <a class="text-decoration-underline pointer" [routerLink]="null" (click)="openModalUsers(feedback.users)">
          <span *ngFor="let user of feedback.users; let last = last">{{ user.firstName }} {{ user.lastName }}{{ last ? '' : ', ' }}</span>
        </a>
      </span>
      <div *ngIf="feedback.feedback.recipientType === FeedbackRecipientType.USER && feedback.users.length > 2">
        <b>{{ 'feedback.message.Feedback envoyé à' | translate }}</b>
        :
        {{ feedback.users[0].firstName }} {{ feedback.users[0].lastName }}
        <a class="text-decoration-underline pointer" [routerLink]="null" (click)="openModalUsers(feedback.users)">
          {{ 'feedback.message.et \{\{size\}\} autres personnes ' | translate : { size: feedback.users.length } }}
        </a>
      </div>
      <span *ngIf="feedback.feedback.recipientType === FeedbackRecipientType.GROUP && feedback.feedback.groupName === null">
        <b>{{ 'feedback.message.Feedback envoyé à' | translate }}</b>
        :
        <i class="fa-light fa-users me-2"></i>
        <a class="text-decoration-underline pointer" [routerLink]="null" (click)="openModalUsers(feedback.users)">
          {{ feedback.users.length }} {{ 'feedback.message.personnes ' | translate }}
        </a>
      </span>
      <span *ngIf="feedback.feedback.recipientType === FeedbackRecipientType.GROUP && feedback.feedback.groupName !== null">
        <b>{{ 'feedback.message.Feedback envoyé à' | translate }}</b>
        :
        <i class="fa-light fa-users me-2"></i>
        <a class="text-decoration-underline pointer" [routerLink]="null" (click)="openModalUsers(feedback.users)">
          {{ feedback.feedback.groupName }}
        </a>
      </span>
      <span *ngIf="feedback.feedback.recipientType === FeedbackRecipientType.GLOBAL_TEAM">
        <b>{{ 'feedback.message.Feedback envoyé à' | translate }}</b>
        :
        <i class="fa-light fa-users me-2"></i>
        <a class="text-decoration-underline pointer" [routerLink]="null" (click)="openModalUsers(feedback.users)">
          {{ 'common.enum.FeedbackRecipientType.' + FeedbackRecipientType.GLOBAL_TEAM.lib | translate }}
        </a>
      </span>
      <span *ngIf="feedback.feedback.recipientType === FeedbackRecipientType.DIRECT_TEAM">
        <b>{{ 'feedback.message.Feedback envoyé à' | translate }}</b>
        :
        <i class="fa-light fa-users me-2"></i>
        <a class="text-decoration-underline pointer" [routerLink]="null" (click)="openModalUsers(feedback.users)">
          {{ 'common.enum.FeedbackRecipientType.' + FeedbackRecipientType.DIRECT_TEAM.lib | translate }}
        </a>
      </span>
    </div>

    <div class="col-auto align-self-baseline">
      <div class="me-2 text-end my-fs-1">
        <i class="fa-light fa-clock"></i>
        {{ feedback.feedback.creationDate | localizedDate }}
      </div>
    </div>
  </div>

  <div class="row g-0 align-items-center" *ngIf="feedback.feedback.idUserSender !== idUserLogged">
    <div class="col-auto">
      <hiji-user-avatar
        style="width: 50px; height: 50px"
        [idUser]="feedback.feedback.idUserSender"
        [avatarExtension]="feedback.avatarExtension"
        [defaultBlueAvatar]="true"
      ></hiji-user-avatar>
    </div>

    <div class="col">
      <div class="row align-items-center">
        <div class="col my-fs-3">
          <b>{{ feedback.firstName }} {{ feedback.lastName }}</b>
        </div>
        <div class="row" *ngIf="displayFunctionFront">
          <div class="col my-fs-2 my-fs-lg-3">
            {{ feedback.fonction }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row justify-content-center mt-2" *ngIf="feedback.category.idFeedbackCategory !== 0">
    <div class="col-12">
      <div class="bg-primary text-white fw-bolder my-fs-3 p-3">
        <i class="{{ feedback.category.icon }} me-2"></i>
        {{ feedback.category.name | ms }}
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="bg-info p-3 text-white" style="border-bottom-right-radius: 1rem; border-bottom-left-radius: 1rem">
        <div class="my-fs-4 fw-bolder">
          <i class="fa-light fa-envelope me-2"></i>
          Message :
        </div>
        <div class="my-fs-3" style="white-space: pre-wrap">
          {{ feedback.feedback.message }}
        </div>
      </div>
    </div>
  </div>

  <div class="position-absolute d-flex gap-2 end-0 translate-middle-y">
    <div
      class="py-1 p-2 rounded-3 border border-1 shadow-1 pointer"
      [class.bg-white]="!reacted(FeedbackReaction.PARTY)"
      [class.bg-info]="reacted(FeedbackReaction.PARTY)"
      [class.text-white]="reacted(FeedbackReaction.PARTY)"
      (click)="react(FeedbackReaction.PARTY)"
    >
      <i class="fa-light fa-glass-cheers" [class.text-white]="reacted(FeedbackReaction.PARTY)" [class.text-primary]="!reacted(FeedbackReaction.PARTY)"></i>
      ({{ getNbReact(FeedbackReaction.PARTY) }})
    </div>
    <div
      class="py-1 p-2 rounded-3 border border-1 shadow-1 pointer"
      [class.bg-white]="!reacted(FeedbackReaction.HEART)"
      [class.bg-info]="reacted(FeedbackReaction.HEART)"
      [class.text-white]="reacted(FeedbackReaction.HEART)"
      (click)="react(FeedbackReaction.HEART)"
    >
      <i class="fa-light fa-heart" [class.text-danger]="!reacted(FeedbackReaction.HEART)" [class.text-white]="reacted(FeedbackReaction.HEART)"></i>
      ({{ getNbReact(FeedbackReaction.HEART) }})
    </div>
    <div
      class="py-1 p-2 rounded-3 border border-1 shadow-1 pointer"
      [class.bg-white]="!reacted(FeedbackReaction.LIKE)"
      [class.bg-info]="reacted(FeedbackReaction.LIKE)"
      [class.text-white]="reacted(FeedbackReaction.LIKE)"
      (click)="react(FeedbackReaction.LIKE)"
    >
      <i class="fa-light fa-thumbs-up" [class.text-tertiary]="!reacted(FeedbackReaction.LIKE)" [class.text-white]="reacted(FeedbackReaction.LIKE)"></i>
      ({{ getNbReact(FeedbackReaction.LIKE) }})
    </div>
    <div
      class="py-1 p-2 rounded-3 border border-1 shadow-1 pointer"
      [class.bg-white]="!reacted(FeedbackReaction.INTERROGATION)"
      [class.bg-info]="reacted(FeedbackReaction.INTERROGATION)"
      [class.text-white]="reacted(FeedbackReaction.INTERROGATION)"
      (click)="react(FeedbackReaction.INTERROGATION)"
    >
      <i
        class="fa-light fa-question"
        [class.text-warning]="!reacted(FeedbackReaction.INTERROGATION)"
        [class.text-white]="reacted(FeedbackReaction.INTERROGATION)"
      ></i>
      ({{ getNbReact(FeedbackReaction.INTERROGATION) }})
    </div>
  </div>
</div>
